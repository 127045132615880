<template>
  <div class="page">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <header class="header">
        <img src="./img/header_bg.png" alt="图片加载失败..." />
      </header>
      <div class="main">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="list-content"
            v-for="(item, index) in listData"
            :key="index"
            @click="toRouteTitle(item)"
          >
            <div class="list-sidebar">
              <img :src="item.topicPicture" />
            </div>
            <div class="list-wrapper">
              <div class="left-title">
                {{ item.topicTitle }}
              </div>
              <div class="left-content">{{ item.talentType }}</div>
              <div class="left-wrap">
                <div class="iconText">
                  <div class="icon icon-up">
                    <img src="./img/icon-hot.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.readCount }}</span>
                  </div>
                </div>
                <div class="iconText">
                  <div class="icon icon-up">
                    <img v-if="!item.hasPraised" src="./img/icon-like.png" />
                    <img v-else src="./img/icon-like-red.png" alt="" />
                  </div>
                  <div class="text">
                    <span :class="{ hasPraised: item.hasPraised }">
                      {{ item.praiseCount }}
                    </span>
                  </div>
                </div>
                <div class="iconText">
                  <div class="icon">
                    <img src="./img/icon-msg.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.commentCount }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
    <footer v-if="!isApply">
      <div class="btn" @click="apply">
        <span>我要申请</span>
      </div>
    </footer>
    <v-comments
      ref="vComments"
      v-model="text"
      isAutoClose
      :show.sync="isShowComments"
      @acceptSend="acceptSend"
    ></v-comments>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getTopicListURL,
  cancelPraiseURL,
  addPraiseURL,
  addCommentURL,
  getUserTalentStatusURL,
} from "./api.js";
import { vComments } from "@/components/control/index";
import { gloabalCount } from "@/utils/common";
export default {
  name: "partnerStyleList",
  components: {
    vComments,
  },
  data() {
    return {
      isShowComments: false,
      isApply: false, // 是否已经申请
      text: "", // 文本内容
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 20,
      curIndex: undefined, // 当前点击的索引
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.getIsApply();
  },
  mounted() {
    gloabalCount("", 7, 1);
  },
  methods: {
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            tenantId: this.tenantId,
            userId: this.userId,
            status: 1,
            topicType: 1,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.curPage++;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      // this.listData = [];
      this.onLoad();
    },
    // 接受评论组件发送过来的信息
    acceptSend(val) {
      if (!(val.trim && val.trim())) {
        this.$toast({ message: "请输入内容", duration: 1500 });
        return;
      }
      console.log(val, this.text);
      const item = this.curData;
      // 1. 调接口
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "发送中...",
      });
      this.$axios
        .post(addCommentURL, {
          topicId: item.id, // 话题id
          commentId: null, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 0, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
          tenantId: this.tenantId || undefined,
          communityId: this.communityId || undefined,
          roomId: this.houseId || undefined,
        })
        .then((res) => {
          if (res.code === 200) {
            // 2. 关闭弹框
            this.isShowComments = false;
            // 3. 关闭后清楚数据
            this.text = "";
            item.commentCount += 1;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 点赞和取消点赞
    toClickLike(item) {
      this.$axios
        .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
          params: {
            targetId: item.id,
            userId: this.userId,
            tenantId: this.tenantId || undefined,
            communityId: this.communityId || undefined,
            roomId: this.houseId || undefined,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res, "----");
            if (item.hasPraised) {
              // 取消点赞
              item.hasPraised = false;
              item.praiseCount -= 1;
            } else {
              // 点赞
              item.hasPraised = true;
              item.praiseCount += 1;
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    // 点击标题跳转标题详情页
    toRouteTitle(item) {
      this.$router.push({
        name: "partnerStyleTitle",
        query: {
          id: item.id,
        },
      });
    },
    // 点击评论图标跳出评论页面
    toComments(item) {
      this.curData = item;
      this.isShowComments = true;
      // 获取焦点
      setTimeout(() => {
        this.$refs.vComments.getFocus();
      }, 500);
    },
    getIsApply() {
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "请求中...",
      });
      this.$axios
        .get(getUserTalentStatusURL, {
          params: {
            userId: this.userId, // 评论人ID
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.isApply = res.data;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 我要申请按钮
    apply() {
      this.$router.push({
        name: "talent",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f5f5f5;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  header.header {
    // position: fixed;
    // left: 0;
    // top: 0;
    width: 100%;
    height: 324px;
    overflow: hidden;
    z-index: 2;
    > img {
      height: 100%;
      width: 100%;
    }
  }
  .main {
    box-sizing: border-box;
    // height: calc(100% - 516px);
    // overflow-y: auto;
    // overflow-x: hidden; /* van-list 上层级不能包含 overflow */
    margin: 32px 0 0 0;
    padding: 0px 32px 116px 32px; // 底部内边距需加上 “按钮” 高度
    .list-content {
      border-radius: 16px;
      background: #ffffff;
      display: flex;
      overflow: hidden;
      margin-bottom: 32px;
      height: 192px;
      .list-sidebar {
        width: 192px;
        min-width: 192px;
        height: 192px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .list-wrapper {
        flex: 1;
        max-width: calc(100% - 256px);
        padding: 0 32px;
        .left-title {
          margin: 24px 0px 16px 0px;
          height: 44px;
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 44px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .left-content {
          color: #666666;
          height: 32px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 32px;
          padding-left: 4px;
          margin-bottom: 28px;
        }
        .left-wrap {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          line-height: 32px;
          .iconText {
            display: flex;
            margin-right: 32px;
            color: #a8a8a8;
            align-items: center;
            .icon {
              width: 32px;
              height: 32px;
              overflow: hidden;
              &.icon-up {
                > img {
                  margin-top: -4px;
                }
              }
              > img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              margin-left: 4px;
              display: flex;
              align-items: center;
              height: 32px;
              span {
                &.hasPraised {
                  color: #ff853a;
                }
                font-size: 24px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
  footer {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 96px;
      color: #ffffff;
      background: #007eff;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
  }
}
</style>
